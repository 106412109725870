import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import LoadingIcon from './LoadingIcon';

const useResource = (resource) => {
	const [data, setData] = useState([]);

	const fetchData = () => {
		// eslint-disable-next-line no-undef
		axios.get(`${process.env.REACT_APP_API_HOST}=${resource}`).then(res => {
			setTimeout(() => {
				setData(res.data);
			}, 500);
		});
	};

	useEffect(() => {
		fetchData();
	}, []);

	return data;
};

const renderLinks = (data, resource) => {
	const res = data.map(item => (
		<div key={item._id}>
			<a target="_blank" href={item.url} rel="noopener noreferrer" className="hero-title">{item.name}</a>
		</div>
	));

	if (resource === 'codecanyon' || resource === 'creativeMarket' || resource === 'github' ) {
		let link = 'https://codecanyon.net/user/lizardthemes';
		if (resource === 'creativeMarket') link = 'https://creativemarket.com/LizardThemes/shop';
		if (resource === 'github') link = 'https://github.com/steve-232';

		const seeMoreLink = (
			<div key="1">
				<a target="_blank" href={link} rel="noopener noreferrer" className="hero-title active">See more</a>
			</div>
		);

		res.push(seeMoreLink);
	}

	return res;
};

const RenderItems = (props) => {
	const { resource } = props;
	const data = useResource(resource);

	return (
		<div className="popup-box-inner">
			{ data.length ? renderLinks(data, resource) : <LoadingIcon /> }
		</div>
	);
};

RenderItems.propTypes = {
	resource: PropTypes.string
};

export default RenderItems;