import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RenderItems from './RenderItems';
import { getImageSrc } from 'utils';
import { useKey } from 'customHooks';
import 'sass/popupBox.scss';

const PopupBox = (props) => {
	const [playAnimation, togglePlayAnimation] = useState(false);
	const [closeBtnClicked, setCloseBtnClicked] = useState(false);
	const { logoName, mouseClick } = props;

	const close = () => {
		setCloseBtnClicked('clicked');
		setTimeout(() => {
			togglePlayAnimation(false);
		}, 100);
		setTimeout(() => mouseClick(), 450);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			togglePlayAnimation('active');	
		}, 500);

		return () => clearTimeout(timer);
	}, []);

	useKey('keydown', ({ key }) => {
		if (key === 'Escape') close();
	}, []);

	return (
		<div className="popup-box">
			<div className={`close-popup-box ${playAnimation} ${closeBtnClicked}`} onClick={close} />

			<div className={`hero-line top ${playAnimation}`}></div>
			<div className={`hero-line top-left ${playAnimation}`}></div>
			<div className={`hero-line bottom ${playAnimation}`}></div>
			<div className={`hero-line bottom-right ${playAnimation}`}></div>

			<img src={getImageSrc(logoName)} className={`popup-box-logo ${playAnimation} ${logoName}`} />

			<RenderItems resource={logoName} />
		</div>
	);
};

PopupBox.propTypes = {
	logoName: PropTypes.string,
	mouseClick: PropTypes.func
};

export default PopupBox;