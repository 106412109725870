import React, { useState } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Logo from 'components/Logo';
import Circle from 'components/Circle';
import PopupBox from 'components/PopupBox';
import Copyright from 'components/Copyright';
import { useKey } from 'customHooks';

function Home() {
	const [showPopupBox, togglePopupBox] = useState(false);
	const [logo, setLogo] = useState('');

	useKey('keydown', ({ key }) => {
		if (!showPopupBox) {
			switch(key) {
			case '1':
				setLogo('codecanyon');
				togglePopupBox(!showPopupBox);
				break;
			case '2':
				setLogo('creativeMarket');
				togglePopupBox(!showPopupBox);
				break;
			case '3':
				setLogo('android');
				togglePopupBox(!showPopupBox);
				break;
			case '4':
				setLogo('yt');
				togglePopupBox(!showPopupBox);
				break;
			case '5':
				setLogo('github');
				togglePopupBox(!showPopupBox);
				break;
			}
		}
	}, [showPopupBox]);

	return (
		<>
			<Logo />
			<div className="main-content">
				<Circle name="codecanyon" number={1} mouseClick={(logoName) => { setLogo(logoName); togglePopupBox(!showPopupBox); }} />
				<Circle name="creativeMarket" number={2} mouseClick={(logoName) => { setLogo(logoName); togglePopupBox(!showPopupBox); }} />
				<Circle name="android" number={3} mouseClick={(logoName) => { setLogo(logoName); togglePopupBox(!showPopupBox); }} />
				<Circle name="yt" number={4} mouseClick={(logoName) => { setLogo(logoName); togglePopupBox(!showPopupBox); }} />
				<Circle name="github" number={5} mouseClick={(logoName) =>  { setLogo(logoName); togglePopupBox(!showPopupBox); }} />
			</div>

			<ReactCSSTransitionGroup
				transitionName="fromBottom"
				transitionEnterTimeout={200}
				transitionLeaveTimeout={200}>
				{ showPopupBox && <PopupBox logoName={logo} mouseClick={() => togglePopupBox(!showPopupBox)} />}
			</ReactCSSTransitionGroup>
			<Copyright />
		</>
	);
}

export default Home;
