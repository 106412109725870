import { useEffect } from 'react';

export const useKey = (eventName, method, dependency = []) => {
	useEffect(() => {
		window.addEventListener(eventName, method);

		return () => {
			window.removeEventListener(eventName, method);
		};
	}, dependency);
};