import React from 'react';
import 'sass/notFound.scss';

function NotFound() {
	return (
		<h1 className="hero-title-v2">404: Page not found</h1>
	);
}

export default NotFound;
