import React from 'react';
import {
	HashRouter,
	Switch,
	Route
} from 'react-router-dom';
import Home from 'views/Home';
import NotFound from 'views/NotFound';
import 'sass/main.scss';

function App() {
	return (
		<HashRouter>
			<div className="wrapper">
				<Switch>
					<Route exact path="/">
						<Home />
					</Route>
					<Route path="*">
						<NotFound />
					</Route>
				</Switch>
			</div>
		</HashRouter>
	);
}

export default App;