import React from 'react';
import PropTypes from 'prop-types';
import { getImageSrc } from 'utils';
import 'sass/circle.scss';

const Circle = (props) => {
	const { name, number, mouseClick } = props;
	
	return (
		<div className="circle-body" onClick={() => mouseClick(name)}>
			<div className="circle">
				<i>{ number }</i>
			</div>
			<img src={ getImageSrc(name) } className={`circle-logo ${name}`} />
		</div>
	);
};

Circle.propTypes = {
	name: PropTypes.string,
	number: PropTypes.number,
	mouseClick: PropTypes.func
};

export default Circle;