import codecanyon from 'assets/imgs/codecanyon.png';
import creativeMarket from 'assets/imgs/creativeMarket.png';
import android from 'assets/imgs/android.png';
import yt from 'assets/imgs/yt.png';
import github from 'assets/imgs/github.png';

export const getImageSrc = (imgName) => {
	if (imgName === 'codecanyon') return codecanyon;
	else if (imgName === 'creativeMarket') return creativeMarket;
	else if (imgName === 'android') return android;
	else if (imgName === 'yt') return yt;
	else if (imgName === 'github') return github;
};